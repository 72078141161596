import {ActionTree, GetterTree, Module, MutationTree} from "vuex";
import {RootState} from "@/store/types";
import { _axios as axios } from "@/plugins/axios";
import {BACKEND_API_URL} from "@/shared/consts";
import {
    DeleteFileDto,
    OutputReportData,
    RegRepState,
    UploadFileToReportDTO
} from "@/modules/genprox/modules/AIFM/modules/reg-reporting/store/types";
import {Fund, FundManager, LegalEntity} from "@/modules/genprox/models/User";
import {Report} from "@/modules/genprox/modules/AIFM/modules/reg-reporting/models/Report";
import _ from "lodash";
import Utils from "@/modules/shared/utils/utils";

export const state: RegRepState = {
    funds: [],
    reports: [],
    fundData: {},
    outputReportData: {
        aif: [],
        aifmName: null,
        aifmNumber: null,
        id: null,
        regulatoryDeRegistrationDate: null,
        regulatoryRegistrationDate: null,
    },
    newReportFormData: {
        currentStep: 1,
        asiReports: [],
        reportType: {
            isFirstReport: true,
            value: '',
        },
        reportYear: {
            value: null,
        },
        selectedASI: [],
        selectedASIName: '',
        selectedZASI: null,
    },
    previousAsiOptions: [],
    assetsDataSchema: [
        {
            nest: 0,
            highlight: true,
            includeInDetails: true,
            code: 'A.',
            segment: 'A. Aktywa trwałe',
            name: 'Aktywa trwałe',
            subAssetType: '',
            amount: null,
        },
        {
            nest: 1,
            highlight: false,
            includeInDetails: false,
            code: 'A. I.',
            segment: 'I. Wartości niematerialne i prawne',
            name: 'Wartości niematerialne i prawne',
            subAssetType: 'OTH_OTH_OTHR',
            amount: null,
        },
        {
            nest: 1,
            highlight: false,
            includeInDetails: false,
            code: 'A. II.',
            segment: 'II. Rzeczowe aktywa trwałe',
            name: 'Rzeczowe aktywa trwałe',
            subAssetType: 'PHY_OTH_OTHR',
            amount: null,
        },
        {
            nest: 1,
            highlight: false,
            includeInDetails: false,
            code: 'A. III.',
            segment: 'III. Należności długoterminowe',
            name: 'Należności długoterminowe',
            subAssetType: 'OTH_OTH_OTHR',
            amount: null,
        },
        {
            nest: 1,
            highlight: true,
            includeInDetails: true,
            code: 'A. IV.',
            segment: 'IV. Inwestycje długoterminowe',
            name: 'Inwestycje długoterminowe',
            subAssetType: '',
            amount: null,
        },
        {
            nest: 2,
            highlight: false,
            includeInDetails: false,
            code: 'A. IV. 1.',
            segment: '1. Nieruchomości',
            name: 'Nieruchomości',
            subAssetType: 'OTH_OTH_OTHR',
            amount: null,
        },
        {
            nest: 2,
            highlight: false,
            includeInDetails: false,
            code: 'A. IV. 2.',
            segment: '2. Wartości niematerialne i prawne',
            name: 'Wartości niematerialne i prawne',
            subAssetType: 'OTH_OTH_OTHR',
            amount: null,
        },
        {
            nest: 2,
            highlight: true,
            includeInDetails: true,
            code: 'A. IV. 3.',
            segment: '3. Długoterminowe aktywa finansowe',
            name: 'Długoterminowe aktywa finansowe',
            subAssetType: '',
            amount: null,
        },
        {
            nest: 3,
            highlight: true,
            includeInDetails: true,
            code: 'A. IV. 3. a.',
            segment: 'a. w jednostkach powiązanych:',
            name: 'w jednostkach powiązanych:',
            subAssetType: '',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'A. IV. 3. a. aa',
            segment: 'aa udziały lub akcje',
            name: 'udziały lub akcje',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'A. IV. 3. a. ab',
            segment: 'ab inne papiery wartościowe',
            name: 'inne papiery wartościowe',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'A. IV. 3. a. ac',
            segment: 'ac udzielone pożyczki',
            name: 'udzielone pożyczki',
            subAssetType: 'SEC_LON_OTHL',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'A. IV. 3. a. ad',
            segment: 'ad inne długoterminowe aktywa finansowe',
            name: 'inne długoterminowe aktywa finansowe',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 3,
            highlight: true,
            includeInDetails: true,
            code: 'A. IV. 3. b.',
            segment: 'b. w pozostałych jednostkach, w których jednostka posiada zaangażowanie w kapitale:',
            name: 'w pozostałych jednostkach, w których jednostka posiada zaangażowanie w kapitale:',
            subAssetType: '',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'A. IV. 3. b. aa',
            segment: 'aa udziały lub akcje',
            name: 'udziały lub akcje',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'A. IV. 3. b. ab',
            segment: 'ab inne papiery wartościowe',
            name: 'inne papiery wartościowe',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'A. IV. 3. b. ac',
            segment: 'ac udzielone pożyczki',
            name: 'udzielone pożyczki',
            subAssetType: 'SEC_LON_OTHL',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'A. IV. 3. b. ad',
            segment: 'ad inne długoterminowe aktywa finansowe',
            name: 'inne długoterminowe aktywa finansowe',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 3,
            highlight: true,
            includeInDetails: true,
            code: 'A. IV. 3. c.',
            segment: 'c. w pozostałych jednostkach:',
            name: 'w pozostałych jednostkach:',
            subAssetType: '',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'A. IV. 3. c. aa',
            segment: 'aa udziały lub akcje',
            name: 'udziały lub akcje',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'A. IV. 3. c. ab',
            segment: 'ab inne papiery wartościowe',
            name: 'inne papiery wartościowe',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'A. IV. 3. c. ac',
            segment: 'ac udzielone pożyczki',
            name: 'udzielone pożyczki',
            subAssetType: 'SEC_LON_OTHL',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'A. IV. 3. c. ad',
            segment: 'ad inne długoterminowe aktywa finansowe',
            name: 'inne długoterminowe aktywa finansowe',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 2,
            highlight: false,
            includeInDetails: false,
            code: 'A. IV. 4.',
            segment: '4. Inne inwestycje długoterminowe',
            name: 'Inne inwestycje długoterminowe',
            subAssetType: 'OTH_OTH_OTHR',
            amount: null,
        },
        {
            nest: 1,
            highlight: false,
            includeInDetails: false,
            code: 'A. V.',
            segment: 'V. Długoterminowe rozliczenia międzyokresowe',
            name: 'Długoterminowe rozliczenia międzyokresowe',
            subAssetType: 'OTH_OTH_OTHR',
            amount: null,
        },
        {
            nest: 0,
            highlight: true,
            includeInDetails: true,
            code: 'B.',
            segment: 'B. Aktywa obrotowe',
            name: 'Aktywa obrotowe',
            subAssetType: '',
            amount: null,
        },
        {
            nest: 1,
            highlight: false,
            includeInDetails: false,
            code: 'B. I.',
            segment: 'I. Zapasy',
            name: 'Zapasy',
            subAssetType: 'OTH_OTH_OTHR',
            amount: null,
        },
        {
            nest: 1,
            highlight: false,
            includeInDetails: false,
            code: 'B. II.',
            segment: 'II. Należności krótkoterminowe',
            name: 'Należności krótkoterminowe',
            subAssetType: 'OTH_OTH_OTHR',
            amount: null,
        },
        {
            nest: 1,
            highlight: true,
            includeInDetails: true,
            code: 'B. III.',
            segment: 'III. Inwestycje krótkoterminowe',
            name: 'Inwestycje krótkoterminowe',
            subAssetType: '',
            amount: null,
        },
        {
            nest: 2,
            highlight: true,
            includeInDetails: true,
            code: 'B. III. 1.',
            segment: '1. Krótkoterminowe aktywa finansowe',
            name: 'Krótkoterminowe aktywa finansowe',
            subAssetType: '',
            amount: null,
        },
        {
            nest: 3,
            highlight: true,
            includeInDetails: true,
            code: 'B. III. 1. a.',
            segment: 'a. w jednostkach powiązanych',
            name: 'w jednostkach powiązanych',
            subAssetType: '',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'B. III. 1. a. aa',
            segment: 'aa udziały lub akcje',
            name: 'udziały lub akcje',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'B. III. 1. a. ab',
            segment: 'ab inne papiery wartościowe',
            name: 'inne papiery wartościowe',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'B. III. 1. a. ac',
            segment: 'ac udzielone pożyczki',
            name: 'udzielone pożyczki',
            subAssetType: 'SEC_LON_OTHL',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'B. III. 1. a. ad',
            segment: 'ad inne krótkoterminowe aktywa finansowe',
            name: 'inne krótkoterminowe aktywa finansowe',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 3,
            highlight: true,
            includeInDetails: true,
            code: 'B. III. 1. b.',
            segment: 'b. w pozostałych jednostkach',
            name: 'w pozostałych jednostkach',
            subAssetType: '',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'B. III. 1. b. aa',
            segment: 'aa udziały lub akcje',
            name: 'udziały lub akcje',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'B. III. 1. b. ab',
            segment: 'ab inne papiery wartościowe',
            name: 'inne papiery wartościowe',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'B. III. 1. b. ac',
            segment: 'ac udzielone pożyczki',
            name: 'udzielone pożyczki',
            subAssetType: 'SEC_LON_OTHL',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: true,
            code: 'B. III. 1. b. ad',
            segment: 'ad inne krótkoterminowe aktywa finansowe',
            name: 'inne krótkoterminowe aktywa finansowe',
            subAssetType: 'SEC_UEQ_UEQY',
            amount: null,
        },
        {
            nest: 3,
            highlight: true,
            includeInDetails: false,
            code: 'B. III. 1. c.',
            segment: 'c. środki pieniężne i inne aktywa pieniężne',
            name: 'środki pieniężne i inne aktywa pieniężne',
            subAssetType: '',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: false,
            code: 'B. III. 1. c. aa',
            segment: 'aa środki pieniężne w kasie i na rachunkach',
            name: 'środki pieniężne w kasie i na rachunkach',
            subAssetType: 'SEC_CSH_OTHC',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: false,
            code: 'B. III. 1. c. ab',
            segment: 'ab inne środki pieniężne',
            name: 'inne środki pieniężne',
            subAssetType: 'SEC_CSH_OTHC',
            amount: null,
        },
        {
            nest: 4,
            highlight: false,
            includeInDetails: false,
            code: 'B. III. 1. c. ac',
            segment: 'ac inne aktywa pieniężne',
            name: 'inne aktywa pieniężne',
            subAssetType: 'SEC_CSH_OTHC',
            amount: null,
        },
        {
            nest: 2,
            highlight: false,
            includeInDetails: false,
            code: 'B. III. 2.',
            segment: '2. Inne inwestycje krótkoterminowe',
            name: 'Inne inwestycje krótkoterminowe',
            subAssetType: 'OTH_OTH_OTHR',
            amount: null,
        },
        {
            nest: 1,
            highlight: false,
            includeInDetails: false,
            code: 'B. IV.',
            segment: 'IV. Krótkoterminowe rozliczenia międzyokresowe',
            name: 'Krótkoterminowe rozliczenia międzyokresowe',
            subAssetType: 'OTH_OTH_OTHR',
            amount: null,
        },
        {
            nest: 0,
            highlight: false,
            includeInDetails: false,
            code: 'C.',
            segment: 'C. Należne wpłaty na kapitał (fundusz) podstawowy',
            name: 'Należne wpłaty na kapitał (fundusz) podstawowy',
            subAssetType: 'OTH_OTH_OTHR',
            amount: null,
        },
        {
            nest: 0,
            highlight: false,
            includeInDetails: false,
            code: 'D.',
            segment: 'D. Udziały (akcje) własne',
            name: 'Udziały (akcje) własne',
            subAssetType: 'OTH_OTH_OTHR',
            amount: null,
        },
    ],
    liabilitiesDataSchema: [
        {
            nest: 0,
            highlight: false,
            code: 'A.',
            segment: 'A. Kapitał (fundusz) własny',
            amount: null,
        },
        {
            nest: 0,
            highlight: false,
            code: 'B.',
            segment: 'B. Zobowiązania i rezerwy na zobowiązania',
            amount: null,
        },
    ],
    additionalDataSchema: [
        {
            nest: 0,
            highlight: false,
            code: '',
            segment: 'Kapitał (fundusz) podstawowy',
            amount: null,
        },
        {
            nest: 0,
            highlight: false,
            code: '',
            segment: 'Suma kapitału (funduszu) podstawowego dla 5 największych inwestorów',
            amount: null,
        },
    ],
    euroExchangeRates: {},
    loadLastCompletedReport: false,
}

export const getters: GetterTree<RegRepState, RootState> = {
    getAssetsSchema(state) {
        return state.assetsDataSchema;
    },
    getLiabilitiesSchema(state) {
        return state.liabilitiesDataSchema;
    },
    getAdditionalSchema(state) {
        return state.additionalDataSchema;
    },
    getNewReportFormData(state) {
        return state.newReportFormData;
    },
    getSelectedASI(state) {
        return state.newReportFormData.selectedASI.find((el: any) => el.name === state.newReportFormData.selectedASIName)
    },
    getSelectedASIName(state) {
        return state.newReportFormData.selectedASIName;
    },
    getOutputReportData(state) {
        return state.outputReportData;
    },
    getFundData(state) {
        return state.fundData
    },
    getLoadLastCompletedReport(state) {
        return state.loadLastCompletedReport
    }
}

export const mutations: MutationTree<RegRepState> = {
    setOutputReportData(state, payload: OutputReportData): void {
        state.outputReportData = payload;
    },
    setFunds(state, payload: Fund[]): void {
        state.funds = payload;
    },
    setReports(state, payload: Report[]): void {
        state.reports = payload;
    },
    clearReports(state) {
        state.reports = [];
    },
    updateReport(state, payload: Report): void {
        let updated = false;
        state.reports = state.reports.map(report => { // update existing report
            if (report.id === payload.id) {
                updated = true;
                return payload;
            }
            return report;
        });
        if (!updated) { // push new report if doesn't exist yet
            state.reports.push(payload);
        }
    },
    setFundData(state, payload: FundManager): void {
        state.fundData = payload;
    },
    setReportYear(state, payload?: string) {
        state.newReportFormData.reportYear.value = payload;
    },
    setReportType(state, isFirstReport: boolean) {
        state.newReportFormData.reportType.isFirstReport = isFirstReport;
        state.newReportFormData.reportType.value = null;
    },
    setCorrectionNumber(state, payload: any) {
        state.newReportFormData.reportType.value = payload;
    },
    setSelectedASIs(state, options: Array<string>) {
        state.newReportFormData.selectedASI?.forEach((asi: any) => {
            const existingIndex = state.previousAsiOptions?.findIndex((el: any) => el.id === asi.id)
            
            if (existingIndex >= 0) {
                // replace asi option
                state.previousAsiOptions.splice(existingIndex, 1, asi)
            } else {
                // add asi option
                state.previousAsiOptions.push(asi)
            }
        })
        
        state.newReportFormData.selectedASI = [];
        
        options.forEach((option: string) => {
            const previousOption = state.previousAsiOptions.find((el: any) => el.id === option);
            const hasOptionPreviously = previousOption ? true : false;

            const selectedOption = state.outputReportData.aif.find((el: any) => el.id === option);

            state.newReportFormData.selectedASI.push({
                id: hasOptionPreviously ? previousOption.id : selectedOption?.id,
                name: selectedOption?.aifName,
                validated: hasOptionPreviously ? previousOption.validated : false,
                balanceData: hasOptionPreviously ? previousOption.balanceData : null,
                reportData: hasOptionPreviously ? previousOption.reportData : true,
                files: hasOptionPreviously ? previousOption.files : [],
                aifManagementStartDate: hasOptionPreviously ? previousOption.aifManagementStartDate : selectedOption.aifManagementStartDate,
                aifNumber: hasOptionPreviously ? previousOption.aifNumber : selectedOption.aifNumber,
                dateOfDiscontinuationOfAifManagement: hasOptionPreviously ? previousOption.dateOfDiscontinuationOfAifManagement : selectedOption.dateOfDiscontinuationOfAifManagement,
            })
        })
    },
    setAsiBalance(state, payload: { id: string, reportData: boolean, validated: boolean, balanceData: any }) {
        const asiIndex: number = state.newReportFormData?.selectedASI?.findIndex((asi: any) => asi?.id === payload.id)
        if (asiIndex >= 0) {
            state.newReportFormData.selectedASI[asiIndex].reportData = payload.reportData
            state.newReportFormData.selectedASI[asiIndex].validated = payload.validated
            state.newReportFormData.selectedASI[asiIndex].balanceData = payload.balanceData
        }
    },
    toggleReportData(state, optionName: string) {
        const option = state.newReportFormData.selectedASI.find((el: any) => el.name === optionName);
        option.reportData = !option.reportData;
    },
    incrementCurrentStep(state): void {
        state.newReportFormData.currentStep++;
    },
    decrementCurrentStep(state): void {
        if(state.newReportFormData.currentStep > 1) {
            state.newReportFormData.currentStep--;
        }
    },
    resetCurrentStep(state): void {
        state.newReportFormData.currentStep = 1;
    },
    setCurrentStep(state, stepNumber: number): void {
        state.newReportFormData.currentStep = stepNumber;
    },
    setNewReportFormData(state, payload: any): void {
        state.newReportFormData = JSON.parse(payload);
        const selectedASI = state.newReportFormData?.selectedASI?.find((el: any) => el.name === state.newReportFormData.selectedASIName);

        if(state.newReportFormData?.currentStep === 6 && !selectedASI?.balanceData) {
            state.newReportFormData.currentStep = 5;
        }

        state.newReportFormData?.selectedASI?.forEach((asi: any) => {
            asi?.balanceData?.assets?.forEach((detail: any) => {
                if (detail?.details?.length) {
                    detail.details = detail.details.map((el: any) => {
                        el.code = detail?.code
                        
                        return el
                    })
                }
            })
        })
    },
    resetNewReportFormData(state): void {
        state.newReportFormData = {
            currentStep: 1,
            asiReports: [],
            reportType: {
                isFirstReport: true,
                value: '',
            },
            reportYear: {
                value: null,
            },
            selectedASI: [],
            selectedASIName: '',
            selectedZASI: null,
        }
        state.previousAsiOptions = []
    },
    setSelectedASIName(state, name: string) {
        state.newReportFormData.selectedASIName = name;
    },
    setSelectedASIBalance(state, payload: { asiName: string, balance: any }) {
        const selectedASI = state.newReportFormData.selectedASI.find((el: any) => el.name == payload.asiName);
        selectedASI.balanceData = payload.balance;
    },
    setSelectedASIBalanceValidation(state, payload: { asiName: string, validated: any }) {
        const selectedASI = state.newReportFormData.selectedASI.find((el: any) => el.name == payload.asiName);
        selectedASI.validated = payload.validated;
    },
    setEuroExchangeRates(state, payload: Record<string, string | number>) {
        state.euroExchangeRates = payload;
    },
    setLoadLastCompletedReport(state, value: boolean) {
        state.loadLastCompletedReport = value;
    },
    setBalanceRowValue(state, payload: { table: 'assets' | 'liabilities' | 'additionals', code: string, value: string }) {
        const table = state.newReportFormData.selectedASI.find((el: any) => el.name === state.newReportFormData.selectedASIName);

        if (table?.balanceData === null) {
            table.balanceData = {
                additionals: _.cloneDeep(state.additionalDataSchema),
                assets: _.cloneDeep(state.assetsDataSchema),
                liabilities: _.cloneDeep(state.liabilitiesDataSchema)
            }
        }

        if (table?.balanceData[payload.table]) {
            const searchBy = payload.table === 'assets' ? 'code' : 'segment'

            const option = table?.balanceData[payload.table]?.find((el: any) => el[searchBy] === payload.code)

            if (option) {
                option.amount = payload?.value ? payload.value : null
            } else {
                const errorMessage = `Couldn't find option for balanceData[${payload.table}]. Searching for "${payload.code}"`
                console.error(errorMessage)
                throw new Error(errorMessage)
            }
        } else {
            const errorMessage = `Couldn't find table "${payload.table}" in balance data`
            console.error(errorMessage)
            throw new Error(errorMessage)
        }
    }
}

export const actions: ActionTree<RegRepState, RootState> = {
    async getFunds({commit, dispatch}): Promise<LegalEntity[]> {
        try {
            const {data} = await axios.get(`${BACKEND_API_URL}/structure/fund-manager/funds`);
            const funds: Fund[] = data.map((legalEntity: any) =>
                ({
                    id: legalEntity.legalEntityId,
                    logo: legalEntity.legalEntityLogo,
                    name: legalEntity.legalEntityName,
                    dateOfDiscontinuationOfAifManagement: legalEntity.dateOfDiscontinuationOfAifManagement,
                }));
            commit('setFunds', funds);
            await dispatch('getEuroExchangeRates');
            return funds;
        } catch (e) {
            return new Array<any>();
        }
    },
    async getEuroExchangeRates({commit}): Promise<any> {
      const {data} = await axios.get(`${BACKEND_API_URL}/crm/euro-exchange-rates`);
      commit('setEuroExchangeRates', data);
      
      return data;
    },
    async getOutputReportData({commit}): Promise<any> {
        const { data } = await axios.get(`${BACKEND_API_URL}/regulatory-reporting/knf-report/output-report-data`);
        commit('setOutputReportData', data);
        return data;
    },
    async createReporting({commit}, payload: { year: number, fundsId: Array<number>, draft: boolean, data: string | null }): Promise<any> {
        const {data} = await axios.post(`${BACKEND_API_URL}/regulatory-reporting/knf-report`, payload);
        return data;
    },
    async updateReporting({commit}, payload: { data: { year: number, fundsId: Array<number>, draft: boolean, data: string | null }, reportId: string }): Promise<any> {
        const {data} = await axios.put(`${BACKEND_API_URL}/regulatory-reporting/knf-report/${payload.reportId}`, payload.data);
        return data;
    },
    async uploadReportFile({commit}, uploadData: UploadFileToReportDTO): Promise<any> {
        const {data} = await axios.post(`${BACKEND_API_URL}/regulatory-reporting/knf-report/${uploadData.reportId}/input-file`,
            uploadData.payload);
        return data;
    },
    async updateReportFile({commit}, uploadData: UploadFileToReportDTO): Promise<any> {
        const {data} = await axios.put(
            `${BACKEND_API_URL}/regulatory-reporting/knf-report/${uploadData.reportId}/input-file/${uploadData.inputFileId}`,
            uploadData.payload);
        return data;
    },
    async getReports({commit, dispatch}): Promise<any> {
        const {data} = await axios.get(`${BACKEND_API_URL}/regulatory-reporting/knf-report`);
        commit('clearReports');
        dispatch('loadReports', data)
        return data;
    },
    async getReportsByYear({commit}, year: string) {
        const { data } = await axios.get(`${BACKEND_API_URL}/regulatory-reporting/knf-report?year=${year}`)
        return data
    },
    loadReports({ commit }, data: Report[]) {
        const formattedData: Report[] = []

        data.forEach((report: any) => {
            const reportData = JSON.parse(report.data)

            reportData?.selectedASI?.forEach((asi: any) => {
                if (asi?.balanceData && asi?.balanceData?.assets) {
                    asi?.balanceData?.assets?.forEach((asset: any) => {
                        const details: any[] = []
                        if (asset?.details?.length > 0) {
                            asset?.details?.forEach((detail: any) => {
                                const formattedDetail = Utils.formatSubAssetType(_.cloneDeep(detail))

                                details.push(formattedDetail)
                            })
                            asset.details = details
                        }
                    })
                }
            })

            report.data = JSON.stringify(reportData)

            formattedData.push(report)
        })

        commit('setReports', formattedData)
    },
    async getReport({commit}, reportId: string): Promise<any> {
        const {data} = await axios.get(`${BACKEND_API_URL}/regulatory-reporting/knf-report/${reportId}`);
        commit('updateReport', data);
        return data;
    },
    async getReportDataOnly({commit}, reportId: string) {
        const {data} = await axios.get(`${BACKEND_API_URL}/regulatory-reporting/knf-report/${reportId}`);
        return data
    },
    async uploadXmlFile({commit}, payload): Promise<Array<any>> {
        const formData = new FormData();
        const {file, apiQuery, urlType} = payload
        formData.append('file', file)
        const {data} = await axios.post(`${BACKEND_API_URL}${apiQuery}`, formData);
        data.urlType = urlType;
        return data;
    },
    async uploadOutputFile({commit}, payload: { reportId: string, fileId: string }): Promise<Array<any>> {
        const { data } = await axios.post(`${BACKEND_API_URL}/regulatory-reporting/knf-report/${payload.reportId}/output-file`, {
            fileId: payload.fileId,
            selfGenerated: true,
        });

        return data;
    },
    async getXmlKnfReportCodes({commit}): Promise<Array<any>> {
        const {data} = await axios.get(`${BACKEND_API_URL}/regulatory-reporting/knf-report-xml/codes`);
        commit('updateReport', data);
        return data;
    },
    async getXmlReportCodes({commit}): Promise<Array<any>> {
        const {data} = await axios.get(`${BACKEND_API_URL}/regulatory-reporting/sf-report-xml/nodes`);
        commit('updateReport', data);
        return data;
    },
    async getXmlReportDescription({commit}, fileId: string): Promise<Array<any>> {
        const {data} = await axios.get(`${BACKEND_API_URL}/regulatory-reporting/report-xml/description/${fileId}`);
        return data;
    },
    async deleteReportInputFile({commit}, payload: DeleteFileDto): Promise<any> {
        const {data} = await axios.delete(`${BACKEND_API_URL}/regulatory-reporting/knf-report/${payload.reportId}/input-file/${payload.fileId}`)
        return data;
    },
    async generateReport({commit}, reportId: string): Promise<any> {
        const {data} = await axios.put(`${BACKEND_API_URL}/regulatory-reporting/knf-report/${reportId}/generate`)
    },
    async approveReport({commit}, reportId: string): Promise<any> {
        const {data} = await axios.put(`${BACKEND_API_URL}/regulatory-reporting/knf-report/${reportId}/approve`)
    },
    async validateXmlReport({commit}, fileId: string) {
        const {data} = await axios.get(`${BACKEND_API_URL}/regulatory-reporting/report-xml/validate/${fileId}`)
        return data
    }
}


export const regReporting: Module<RegRepState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

