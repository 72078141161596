import {RouteConfig} from "vue-router";
import {BACKEND_BASE_URL} from "@/shared/consts";
import store from "@/store/rootStore";

const whistleBlowerModule = () => import('./containers/whistleblowerModule.vue');
const whistleBlowerBlindModule = () => import('./containers/whistleblowerBlindModule.vue');

export const whistleBlowerRouting : Array<RouteConfig> = [
    {
        path: 'whistleblower',
        name: 'whistleblower',
        component: whistleBlowerModule,
        children: [],
        meta: {
            breadcrumbs: [
                { text: 'Home', href: '/welcome' },
                { text: ':currentContext', href: '' },
                { text: 'Whistleblower', href: '#' },
                { text: ':title', href: '' },
            ],
        },
        async beforeEnter(to, from, next) {
            await store.dispatch('genprox/getAccessModules');
            const accessModules = store.getters['genprox/accessModules'];
            if(accessModules.whistleblower) {
                next();
            } else {
                next(`${to.path}/access-denied`);
            }
        },
    },
    {
        path: 'whistleblower/access-denied',
        name: 'access-denied',
        component: whistleBlowerBlindModule,
        meta: {
            accessModule: 'whistleblower',
        },
        children: [],
    },
];
