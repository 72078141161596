import {RouteConfig} from "vue-router";
import store from "@/store/rootStore";

const reportingModule = () => import('./containers/reportingModule.vue');
const reportingBlindModule = () => import('./containers/reportingBlindModule.vue');

export const reportingRouting : Array<RouteConfig> = [
    {
        path: 'reporting',  /* /fund /company /fund-manager */
        name: 'reporting',
        component: reportingModule,
        meta: {
            breadcrumbs: [
                { text: 'Home', href: '/welcome' },
                { text: ':currentContext', href: '/:currentContext/dashboard' },
                { text: 'Reporting', href: '/:currentContext/reporting' },
            ],
        },
        async beforeEnter(to, from, next) {
            let activeUserData = store.getters['genprox/activeUserData'];
            if(!activeUserData) {
                activeUserData = await store.dispatch('genprox/getActiveUserData');
            }

            const isPortfolioCompany = to.path.includes('/company') ? true : false;

            if (activeUserData?.context?.context == 'fund-manager') {

                if (to?.path?.includes('/fund-manager')) {
                    await store.dispatch('genprox/getAccessModules');
                    const accessModules = store.getters['genprox/accessModules'];
                    if(accessModules.managementReporting) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                } else if (to?.path?.includes('/company')) {
                    const viewedCompany = await store.dispatch('auth/getViewedCompany');
                    const leAccessModules = await store.dispatch('genprox/getLegalEntityAccessModules', viewedCompany);
                    
                    if(leAccessModules.managementReporting) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                } else {
                    const viewedFund = await store.dispatch('auth/getViewedFund');
                    const leAccessModules = await store.dispatch('genprox/getLegalEntityAccessModules', viewedFund);

                    if(leAccessModules.managementReporting) {
                        next();
                    } else {
                        next(`${to.path}/access-denied`);
                    }
                }

            } else {
                if(!isPortfolioCompany) {
                    if(activeUserData?.context?.context == 'fund') {
                        await store.dispatch('genprox/getAccessModules');
                        const accessModules = store.getters['genprox/accessModules'];
                        if(accessModules.managementReporting) {
                            next();
                        } else {
                            next(`${to.path}/access-denied`);
                        }
                    } else {
                        const viewedFund = await store.dispatch('auth/getViewedFund');
                        const leAccessModules = await store.dispatch('genprox/getLegalEntityAccessModules', viewedFund);
    
                        if(leAccessModules.managementReporting) {
                            next();
                        } else {
                            next(`${to.path}/access-denied`);
                        }
                    }
                } else {
                    if(activeUserData?.context?.context == 'company') {
                        await store.dispatch('genprox/getAccessModules');
                        const accessModules = store.getters['genprox/accessModules'];
                        if(accessModules.managementReporting) {
                            next();
                        } else {
                            next(`${to.path}/access-denied`);
                        }
                    } else {
                        const viewedCompany = await store.dispatch('auth/getViewedCompany');
                        const leAccessModules = await store.dispatch('genprox/getLegalEntityAccessModules', viewedCompany);
                        
                        if(leAccessModules.managementReporting) {
                            next();
                        } else {
                            next(`${to.path}/access-denied`);
                        }
                    }
                }
            }

        },
    },
    {
        path: 'reporting/access-denied',
        name: 'reporting-access-denied',
        meta: {
            accessModule: ['managementReporting'],
        },
        component: reportingBlindModule
    }
];
