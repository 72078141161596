import {RouteConfig} from "vue-router";
import {BACKEND_BASE_URL} from "@/shared/consts";

const insightsModule = () => import('./containers/insightsModule.vue');
const insightModule = () => import('./containers/insightModule.vue');

export const insightsRouting: Array<RouteConfig>  = [
    {
        path: 'insights',
        // beforeEnter: (to, from, next) => {
        //     window.location.href = `${BACKEND_BASE_URL}/insights`
        // },
        name: 'insights',
        component: insightsModule,
        meta: {
            breadcrumbs: [
                { text: 'Home', href: '/welcome' },
                { text: 'Insights', href: '' },
            ],
        },
        children: []
    },
    {
        path: 'insights/:slug',
        name: 'insight-by-slug',
        component: insightModule,
        children: [],
        // async beforeEnter(to, from, next) {
        //     await store.dispatch('genprox/getAccessModules');
        //     const accessModules = store.getters['genprox/accessModules'];
        //     if(accessModules.accounting) {
        //         next();
        //     } else {
        //         next(`${to.path.substring(0, to.path.lastIndexOf('/'))}/access-denied`);
        //     }
        // },
    },
];
