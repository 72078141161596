import {menuGuard, outsideEnterGuard} from "@/shared/guards";
import {NavigationGuardNext, Route, RouteConfig} from "vue-router";
import {InvestmentClient, InvestmentClientDetails, ProductSummaryAction} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";
import store from '@/store/rootStore';
import Vue from "vue";
import {InvestorFormViewType} from "@/modules/genprox/modules/fund/modules/fundraising/models/InvestorFormViewType.enum";
import {Profile} from "@/modules/genprox/modules/profile/store/types";

const addNewInvestorModule = () => import('./containers/AddNewInvestorModule.vue');
const newInvestorFormStepOneComponent = () => import('./components/add-new-investor/AddNewInvestorFormStepOne.vue');
const newInvestorFormStepTwoComponent = () => import('./components/add-new-investor/AddNewInvestorFormStepTwo.vue');
const newInvestorFormStepThreeComponent = () => import('./components/add-new-investor/AddNewInvestorFormStepThree.vue');
const newInvestorSuccessComponent = () => import('./components/add-new-investor/AddNewInvestorFormSuccess.vue');

const addNewProductModule = () => import('./containers/NewProductModule.vue');
const addNewProductStepOne = () => import('./components/product/NewProductStepOne.vue');
const addNewProductStepTwo = () => import('./components/product/NewProductStepTwo.vue');
const productSummary = () => import('./components/product/ProductSummary.vue');
const addProductSuccess = () => import('./components/product/AddProductSuccess.vue');

const capitalRiseModule = () => import('./containers/CapitalRiseModule.vue');
const capitalRiseBlindModule = () => import('./containers/CapitalRiseBlindModule.vue');

import { campaignsRouting } from "@/modules/genprox/modules/fund/modules/fundraising/modules/campaigns/campaigns.routing"
import { subscriptionsRouting } from "@/modules/genprox/modules/fund/modules/fundraising/modules/subscriptions/subscriptions.routing"

export const capitalRiseRouting: Array<RouteConfig>  = [
    {
        path: 'fundraising',
        name: 'investors',
        alias: ['investors'],
        meta: {
            breadcrumbs: [
                { text: 'Home', href: '/welcome' },
                { text: ':currentContext', href: '' },
                { text: 'Fundraising', href: '/:currentContext/fundraising' },
                { text: ':title', href: '' },
            ]
        },
        // beforeEnter: menuGuard('/fund/investors'),
        async beforeEnter(to, from, next) {
            await store.dispatch('genprox/getAccessModules');
            const accessModules = store.getters['genprox/accessModules'];
            if(accessModules.marketplace) {
                next();
            } else {
                next(`${to.path}/access-denied`);
            }
        },
        component: capitalRiseModule,
    },
    {
      path: 'capital-rise',
      redirect: 'fundraising'
    },
    {
        path: 'fundraising/access-denied',
        name: 'investors-access-denied',
        alias: ['investors/access-denied'],
        meta: {
            accessModule: ['marketplace'],
        },
        component: capitalRiseBlindModule,
    },
    {
      path: 'capital-rise/access-denied',
      redirect: 'fundraising/access-denied',
      meta: {
        accessModule: ['marketplace'],
      },
    },
    {
        path: 'fundraising/new-investor',
        name: 'newInvestor',
        alias: ['investors/new-investor', 'capital-rise/new-investor'],
        component: addNewInvestorModule,
        beforeEnter: outsideEnterGuard('investors'),
        redirect: { name: 'newInvestorFormStepOne' },
        children: [
            {
                path: 'form/step-one',
                name: 'newInvestorFormStepOne',
                props: () => ({ viewType: InvestorFormViewType.newInvestor }),
                component: newInvestorFormStepOneComponent,
                async beforeEnter(to, from, next) {
                  try {
                    await store.dispatch('campaigns/getLeadForm');
                    next();
                  } catch(e) {
                    if(from.path.includes('company')) {
                      next('/company/fundraising/lead-form-settings');
                    } else {
                      next('/fund/fundraising/lead-form-settings');
                    }
                  }
                }
            },
            {
                path: 'form/step-two',
                name: 'newInvestorFormStepTwo',
                props: () => ({ viewType: InvestorFormViewType.newInvestor }),
                component: newInvestorFormStepTwoComponent,
            },
            {
                path: 'form/step-three',
                name: 'newInvestorFormStepThree',
                props: () => ({ viewType: InvestorFormViewType.newInvestor }),
                component: newInvestorFormStepThreeComponent,
            },
            {
                path: 'form/success',
                name: 'newInvestorFormFinish',
                props: () => ({ viewType: InvestorFormViewType.newInvestor }),
                component: newInvestorSuccessComponent,
            }
        ]
    },
    {
      path: 'capital-rise/new-investor',
      redirect: 'fundraising/new-investor'
    },
    {
      path: 'capital-rise/new-investor/form/step-one',
      redirect: 'fundraising/new-investor/form/step-one'
    },
    {
      path: 'capital-rise/new-investor/form/step-two',
      redirect: 'fundraising/new-investor/form/step-one'
    },
    {
      path: 'capital-rise/new-investor/form/step-three',
      redirect: 'fundraising/new-investor/form/step-one'
    },
    {
      path: 'capital-rise/new-investor/form/success',
      redirect: 'fundraising/new-investor/form/step-one'
    },
    {
      path: 'investor/:id/edit/',
      name: 'investors-edit-investor',
      component: addNewInvestorModule,
      beforeEnter: outsideEnterGuard('investors'),
      redirect: { name: 'investors-edit-investor-step-one' },
      children: [
        {
          path: 'form/step-one',
          name: 'investors-edit-investor-step-one',
          props: (route) => ({ investorId: route.params.id, viewType: InvestorFormViewType.editInvestor }),
          beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
            try {
              await store.dispatch('campaigns/getLeadForm');
              const profileData: Profile = await store.dispatch('investors/getInvestorProfile', to.params.id);

              let profileDataDetails: any = profileData?.details
              
              if (!profileData?.details?.documentExpiryDate) {
                const expiryDateObject = {
                  expiryDate: profileData?.details?.documentExpiryDate,
                  indefinitely: !!(profileData?.details?.documentExpiryIndefinitely)
                }
  
                const details: any = profileData?.details
                details.documentExpiryDate = expiryDateObject
  
                profileDataDetails = details
                profileDataDetails.documentExpiryDate = expiryDateObject
              }

              const investmentData: InvestmentClient = {
                isMailingAddressSameResidential: profileData.mailingAddressSameResidential,
                residentialAddress: profileData.residentialAddress,
                mailingAddress: profileData.mailingAddress,
                surname: profileData.generalData.surname,
                firstName: profileData.generalData.firstName,
                details: {...profileDataDetails, title: profileData.generalData.title, phone: profileData.generalData.phone },
                email: profileData.generalData.email,
                industry: profileData.generalData.industry,
                investmentPotential: profileData.generalData.investmentPotential,
                investmentKnowledge: profileData.generalData.investmentKnowledge,
                notes: profileData.generalData.notes,
                status: profileData.status,
                consents: profileData.consents,
              };
              store.commit('investors/setInvestmentClientToEdit', investmentData);
              next();
            } catch(e) {
              if(from.path.includes('company')) {
                next('/company/fundraising/lead-form-settings');
              } else {
                next('/fund/fundraising/lead-form-settings');
              }
            }
            
          },
          component: newInvestorFormStepOneComponent,
        },
        {
          path: 'form/step-two',
          name: 'investors-edit-investor-step-two',
          props: (route) => ({ investorId: route.params.id, viewType: InvestorFormViewType.editInvestor }),
          beforeEnter: (to, from, next) => {
            if (from.path === '/') {
              next({name: 'investors-edit-investor', params: {id: to.params.id}})
            } else {
              next();
            }
          },
          component: newInvestorFormStepTwoComponent,
        },
        {
          path: 'form/step-three',
          name: 'investors-edit-investor-step-three',
          props: (route) => ({ investorId: route.params.id, viewType: InvestorFormViewType.editInvestor }),
          beforeEnter: (to, from, next) => {
            if (from.path === '/') {
              next({name: 'investors-edit-investor', params: {id: to.params.id}})
            } else {
              next();
            }
          },
          component: newInvestorFormStepThreeComponent,
        }
      ]
    },
    {
      path: 'investor/:id/preview/',
      name: 'investors-preview-investor',
      component: addNewInvestorModule,
      beforeEnter: outsideEnterGuard('investors'),
      redirect: { name: 'investors-preview-investor-step-one' },
      children: [
        {
          path: 'form/step-one',
          name: 'investors-preview-investor-step-one',
          props: (route) => ({ investorId: route.params.id, viewType: InvestorFormViewType.previewInvestor }),
          beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
            try {
              await store.dispatch('campaigns/getLeadForm');
              const profileData: Profile = await store.dispatch('investors/getInvestorProfile', to.params.id);

              let profileDataDetails: any = profileData?.details
              
              if (!profileData?.details?.documentExpiryDate) {
                const expiryDateObject = {
                  expiryDate: profileData?.details?.documentExpiryDate,
                  indefinitely: !!(profileData?.details?.documentExpiryIndefinitely)
                }
  
                const details: any = profileData?.details
                details.documentExpiryDate = expiryDateObject
  
                profileDataDetails = details
                profileDataDetails.documentExpiryDate = expiryDateObject
              }

              const investmentData: InvestmentClient = {
                isMailingAddressSameResidential: profileData.mailingAddressSameResidential,
                residentialAddress: profileData.residentialAddress,
                mailingAddress: profileData.mailingAddress,
                surname: profileData.generalData.surname,
                firstName: profileData.generalData.firstName,
                details: {...profileDataDetails, title: profileData.generalData.title, phone: profileData.generalData.phone },
                email: profileData.generalData.email,
                industry: profileData.generalData.industry,
                investmentPotential: profileData.generalData.investmentPotential,
                investmentKnowledge: profileData.generalData.investmentKnowledge,
                notes: profileData.generalData.notes,
                status: profileData.status,
                consents: profileData.consents,
              };
              store.commit('investors/setInvestmentClientToEdit', investmentData);
              next();
            } catch(e) {
              if(from.path.includes('company')) {
                next('/company/fundraising/lead-form-settings');
              } else {
                next('/fund/fundraising/lead-form-settings');
              }
            }
            
          },
          component: newInvestorFormStepOneComponent,
        },
        {
          path: 'form/step-two',
          name: 'investors-preview-investor-step-two',
          props: (route) => ({ investorId: route.params.id, viewType: InvestorFormViewType.previewInvestor }),
          beforeEnter: (to, from, next) => {
            if (from.path === '/') {
              next({name: 'investors-preview-investor', params: {id: to.params.id}})
            } else {
              next();
            }
          },
          component: newInvestorFormStepTwoComponent,
        },
        {
          path: 'form/step-three',
          name: 'investors-preview-investor-step-three',
          props: (route) => ({ investorId: route.params.id, viewType: InvestorFormViewType.previewInvestor }),
          beforeEnter: (to, from, next) => {
            if (from.path === '/') {
              next({name: 'investors-preview-investor', params: {id: to.params.id}})
            } else {
              next();
            }
          },
          component: newInvestorFormStepThreeComponent,
        }
      ]
    },
    {
        path: 'fundraising/verify',
        name: 'verifyInvestor',
        alias: ['investors/verify'],
        component: addNewInvestorModule,
        // beforeEnter: outsideEnterGuard('investors'),
        redirect: { name: 'verifyInvestorFormStepTwo' },
        children: [
            {
                path: 'step-two',
                name: 'verifyInvestorFormStepTwo',
                props: () => ({ viewType: InvestorFormViewType.verifyInvestor }),
                component: newInvestorFormStepTwoComponent,
            },
            {
                path: 'step-three',
                name: 'verifyInvestorFormStepThree',
                beforeEnter: outsideEnterGuard('verifyInvestorFormStepTwo'),
                props: () => ({ viewType: InvestorFormViewType.verifyInvestor }),
                component: newInvestorFormStepThreeComponent,
            },
        ]
    },
    {
      path: 'capital-rise/verify',
      redirect: 'fundraising/verify'
    },
    {
        path: 'fundraising/product',
        name: 'newProduct',
        component: addNewProductModule,
        alias: ['investors/product'],
        redirect: { name: 'addNewProductStepOne' },
        children: [
            {
                path: 'new/step-one',
                name: 'addNewProductStepOne',
                beforeEnter: outsideEnterGuard('investors'),
                component: addNewProductStepOne,
            },
            {
                path: 'new/step-two',
                name: 'addNewProductStepTwo',
                beforeEnter: outsideEnterGuard('investors'),
                component: addNewProductStepTwo,
            },
            {
                path: 'new/summary',
                name: 'addNewProductSummary',
                beforeEnter: outsideEnterGuard('investors'),
                component: productSummary,
            },
            {
                path: 'summary/:id/for-legal-entity',
                props: route => ({ action: ProductSummaryAction.summaryByLegalEntity, productId: route.params.id}),
                name: 'productSummaryForLegalEntity',
                component: productSummary,
                beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
                  const contextData = store.getters['accounting/getContextData'];

                  if(to.query?.contextOwnerId) {
                    await store.dispatch('genprox/setUserContext', { id: to.query.contextOwnerId });
                  }

                  if(!contextData?.id) {
                    const resp = await store.dispatch('genprox/getContextData');
                    store.commit('accounting/setContextData', resp);
                  }

                  next();
                }
            },
            {
              path: 'summary/:id/for-investment-client',
              props: route => ({ action: ProductSummaryAction.summaryByInvestmentClient, productId: route.params.id}),
              name: 'productSummaryForInvestmentClient',
              component: productSummary,
            },
            {
              path: 'accept/:id',
              name: 'acceptProductById',
              props: route => ({ action: ProductSummaryAction.acceptByIdByInvestmentClient, productId: route.params.id }),
              beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
                if(store.state.genprox.activeUserData?.verified === true){ // default case, enter from application
                  next();
                } else {
                  Vue.prototype.$notify({
                    type: 'error',
                    title: 'You can\'t accept product',
                    text: 'Please verify your account before accepting product'
                  });
                  if(from.name){
                    return false;
                  } else {
                    next('')
                  }
                }
              },
              component: productSummary
            },
            {
              path: 'accept-by-legal-entity/:id',
              name: 'acceptProductByIdByLegalEntity',
              props: route => ({ action: ProductSummaryAction.acceptByIdByLegalEntity, productId: route.params.id }),
              component: productSummary
            },
            {
                path: 'success',
                name: 'addNewProductSuccess',
                component: addProductSuccess
            }
        ]
    },
    {
      path: 'capital-rise/product',
      redirect: 'fundraising/product'
    },
    {
      path: 'capital-rise/product/new/step-one',
      redirect: 'fundraising/product/new/step-one'
    },
    {
      path: 'capital-rise/product/new/step-two',
      redirect: 'fundraising/product/new/step-two'
    },
    {
      path: 'capital-rise/product/new/summary',
      redirect: 'fundraising/product/new/summary'
    },
    {
      path: 'capital-rise/product/summary/:id/for-legal-entity',
      redirect: 'fundraising/product/summary/:id/for-legal-entity'
    },
    {
      path: 'capital-rise/product/summary/:id/for-investment-client',
      redirect: 'fundraising/product/summary/:id/for-investment-client'
    },
    {
      path: 'capital-rise/product/accept/:id',
      redirect: 'fundraising/product/accept/:id'
    },
    {
      path: 'capital-rise/product/accept-by-legal-entity/:id',
      redirect: 'fundraising/product/accept-by-legal-entity/:id'
    },
    {
      path: 'capital-rise/product/success',
      redirect: 'fundraising/product/success'
    },
    ...campaignsRouting,
    ...subscriptionsRouting
]
